import React from "react";
import HospitalImg from "../../Images/hospitalImg.png";
import finTech from "../../Images/finTech.png";
import education from "../../Images/education.png";
import realEstate from "../../Images/realEstate.png";
import logistics from "../../Images/logistics.png";
import hospitality from "../../Images/hospitality.png";
import retail from "../../Images/retail.png";
import travel from "../../Images/travel.png";

const Sectors = () => {
  return (
    <>
      <div className="sector-container">
        <div className="sector-header">
          <h1>Industries we serve</h1>
        </div>
        <div className="sector-content">
          <div className="sector">
            <img src={HospitalImg} alt="" />
            <p>health care</p>
          </div>
          <div className="sector">
            <img src={education} alt="" />
            <p>education</p>
          </div>
          <div className="sector">
            <img src={realEstate} alt="" />
            <p>real estate</p>
          </div>
          <div className="sector">
            <img src={finTech} alt="" />
            <p>FinTech</p>
          </div>

          <div className="sector">
            <img src={logistics} alt="" />
            <p>logistics & distribution</p>
          </div>
          <div className="sector">
            <img src={hospitality} alt="" />
            <p>hospitality</p>
          </div>
          <div className="sector">
            <img src={retail} alt="" />
            <p>retail</p>
          </div>
          <div className="sector">
            <img src={travel} alt="" />
            <p>Travel</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sectors;
