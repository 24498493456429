const techData = [
  {
    technology1: "Web Technologies",
    title1: "Front-End Technologies",
    list1: ["React Js", "Angular Js", ".Net", "Java"],
    title2: "Back-End Technologies",
    list2: ["Node Js", "ROR", ".Net Core", "MVC"],
  },
  {
    technology1: "Mobile Technologies",
    title1: "Mobile Technologies",
    list1: ["Android", "React Native", "Flutter"],
    title2: "",
    list2: [],
  },
  {
    technology1: "Other Technologies",
    title1: "Other Technologies",
    list1: ["DevOps", "Shopify", "Wordpress"],
    title2: "",
    list2: [],
  },
];

export default techData;
