import React, { useRef } from "react";
import Navbar from "../Navbar/Navbar";
import emailjs from "emailjs-com";
import "./ContactUs.css";

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_io21az5",
        "template_96mn90g",
        form.current,
        "8GBuEuYMFNQZcjt1L"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Submitted Successfully !!");
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  return (
    <>
      <Navbar />
      <div className="contact-container">
        <h1 style={{ marginTop: "10px", marginBottom: "30px" }}>Contact us</h1>
        <div className="outlay">
          <div className="btns">
            {/* <button className="India">India + </button>
          <button className="canada">Canada + </button> */}
          </div>
          <div className="contact1">
            <form ref={form} onSubmit={sendEmail}>
              <h1 className="conHeader">Drop Your Query</h1>
              <div>
                <label htmlFor="firstName">Name : </label>
                <input type="text" name="name" className="firstName" required />
              </div>
              <div>
                <label htmlFor="mobileNumber">Phone No. : </label>
                <input
                  type={"tel"}
                  name="phone_No"
                  className="mobileNumber"
                  required
                />
              </div>
              <div>
                <label htmlFor="email">Email : </label>
                <input type={"email"} name="email" className="email" required />
              </div>
              <div>
                <label htmlFor="subject">Subject : </label>
                <input
                  type="text"
                  name="subject"
                  className="subject"
                  required
                />
              </div>
              <div>
                <label htmlFor="message">Message : </label>
                <textarea
                  className="message"
                  required
                  name="message"
                  // defaultValue={defaultValue}
                  cols="25"
                  rows="5"
                  placeholder=" Enter Your Message Here..."
                ></textarea>
              </div>
              <div>
                <button type="submit" className="submitBtn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
