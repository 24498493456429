import React from "react";
import "./Footer.css";
import { FaLinkedin, FaMailBulk } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { ImLocation } from "react-icons/im";
import { FcGoogle } from "react-icons/fc";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="column1">
          <div className="location">
            <h2>Address:</h2>
            <div className="address">
              <ImLocation
                size={75}
                style={{ color: "#ccc", marginRight: "15px" }}
                className="location__symbol"
              />
              <p>
                518-Solaris Business Hub,Opp. Parshwanath Jain mandir, Bhuyangdev Cross Road, Ahmedabad, Gujarat-61 <br></br>         
              
                <br></br>
                204 Copper leaf, opp. Parshwanath Jain mandir, Bhuyangdev Cross Road
                Rasta, Ahmedabad, Gujarat-61
              </p>
            </div>
            {/* <h2>Canada:</h2>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Pariatur, debitis.
            </p> */}
          </div>
        </div>
        <div className="column2">
          <h2>Services:</h2>
          <ul>
            <li>
              <a href="/services">Web Development</a>
            </li>
            <li>
              <a href="/services">Mobile Development</a>
            </li>
            <li>
              <a href="/services">Custom Software Development Services</a>
            </li>
            <li>
              <a href="/services">Cross Platform App Development</a>
            </li>
            <li>
              <a href="/services">App Modernization</a>
            </li>
            <li>
              <a href="/services">E-commerce Services</a>
            </li>
            <li>
              <a href="/services">IT Strategy & Consulting</a>
            </li>
            <li>
              <a href="/services">Digital Marketing</a>
            </li>
            <li>
              <a href="/services">Graphics Designing</a>
            </li>
          </ul>
        </div>
        <div className="column3">
          <h2>Hire A team</h2>
          <ul>
          <li>
              <a href="/team">Ruby On Rails Developers</a>
            </li>
            <li>
              <a href="/team">React JS Developers</a>
            </li>
            <li>
              <a href="/team">React Native Developers</a>
            </li>
            <li>
              <a href="/team">Fullstack Node JS Developers</a>
            </li>
            <li>
              <a href="/team">Fullstack Dot Net Developers</a>
            </li>
            <li>
              <a href="/team">Fullstack ROR Developers</a>
            </li>
          </ul>
        </div>
        <div className="column4">
          <div className="contact">
            <h2>Contact Us:</h2>
            <div className="phone">
              <h3>
                <BsFillTelephoneFill
                  size={25}
                  style={{
                    color: "#ccc",

                    marginTop: "1rem",
                  }}
                />
              </h3>
              <p onClick={() => (window.location = "tel:8511701589")}>
                +91 85117 01589
              </p>
            </div>
            <div className="email">
              <h3>
                <FaMailBulk size={25} style={{ color: "#ccc" }} />
              </h3>
              <p
                onClick={() =>
                  (window.location = "mailto:support@softcodingsolutions.com")
                }
              >
                support@softcodingsolutions.com
              </p>
            </div>
            <h2 style={{ marginTop: "15px" }}>for business inquiries:</h2>
            <div className="email" style={{ marginTop: "15px" }}>
              <h3>
                <FaMailBulk size={25} style={{ color: "#ccc" }} />
              </h3>
              <p
                onClick={() =>
                  (window.location = "mailto:sales@softcodingsolutions.com")
                }
              >
                sales@softcodingsolutions.com
              </p>
            </div>
          </div>
          <div className="social">
            <a href="mailto:sales@softcodingsolutions.com">
              <FcGoogle
                className="socialIcons"
                size={35}
                style={{ marginTop: "1rem" }}
              />
            </a>
            <a href="https://in.linkedin.com/company/softcoding-solutions">
              <FaLinkedin
                className="socialIcons"
                size={35}
                style={{ color: "white", marginTop: "1rem" }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
