import React from "react";
import mobileDevelopment from "../../Images/mobileDevelopment.png";

const MobileDevelopment = () => {
  return (
    <>
      <div className="DevelopmentL mobileDevelopment">
        <div className="ImageL mobileImage">
          <img src={mobileDevelopment} alt="mobile-development" />
        </div>
        <div className="ContentL mobileContent">
          <h2>mobile development</h2>
          <p>
            We design customized mobile application that’s easy to navigate and
            gives better user experience.
          </p>
        </div>
      </div>
    </>
  );
};

export default MobileDevelopment;
