import React from "react";
import StrategyConsultingImg from "../../Images/StrategyConsulting.png";

const StrategyConsulting = () => {
  return (
    <div>
      <div className="DevelopmentR StrategyDevelopment">
        <div className="ContentR StrategyContent">
          <h2>
            it strategy & <br /> consulting
          </h2>
          <p>
            We provide expertise IT solutions and consulting to solve your
            business problems.
          </p>
        </div>
        <div className="ImageR StrategyImage">
          <img src={StrategyConsultingImg} alt="Strategy-development" />
        </div>
      </div>
    </div>
  );
};

export default StrategyConsulting;
