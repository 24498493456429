import React, { useState } from "react";
import "./Navbar.css";
import { FiMenu, FiX } from "react-icons/fi";
import Logo from "../../Images/Logo.png";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <nav className="navbar">
        <a
          href="/"
          className="nav-logo"
          onClick={() => setOpen(false)}
          style={{ fontSize: "40px", fontWeight: "900" }}
        >
          <img src={Logo} alt="logo" />
        </a>
        <div onClick={() => setOpen(!open)} className="nav-icon">
          {open ? (
            <FiX color="#000" size={50} />
          ) : (
            <FiMenu color="#000" size={50} />
          )}
        </div>
        <ul className={open ? "nav-links active" : "nav-links"}>
          <li className="nav-items">
            <a href="/" className="nav-link" onClick={() => setOpen(false)}>
              Home
            </a>
          </li>

          <li className="nav-items">
            <a
              href="/about"
              className="nav-link"
              onClick={() => setOpen(false)}
            >
              About
            </a>
          </li>
          <li className="nav-items">
            <a
              href="/services"
              className="nav-link"
              onClick={() => setOpen(false)}
            >
              Services
            </a>
          </li>
          <li className="nav-items">
            <a href="/team" className="nav-link" onClick={() => setOpen(false)}>
              Hire A Team
            </a>
          </li>
          <li className="nav-items">
            <a
              href="/contact"
              className="nav-link contact-nav"
              onClick={() => setOpen(false)}
            >
              Contact Us
            </a>
          </li>
          <li className="nav-items">
            <a
              href="/careers"
              className="nav-link"
              onClick={() => setOpen(false)}
            >
              Careers
            </a>

          </li>
  
        </ul>
      </nav>      
    </div>
    
  );
};

export default Navbar;
