import React from "react";
import EcommImage from "../../Images/ecommImage.png";

const EcommDevelopment = () => {
  return (
    <div>
      <div className="DevelopmentL ecommDevelopment">
        <div className="ImageL ecommImage">
          <img src={EcommImage} alt="ecomm-development" />
        </div>
        <div className="ContentL ecommContent">
          <h2>E-Commerce Development Services</h2>
          <p>
            Give a hike to your current business by giving it an add-on of
            online business with secure payments.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EcommDevelopment;
