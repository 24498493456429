import React from "react";
import team from "../../Images/Team.png";
import cycle from "../../Images/theCycle.png";
import "./Team.css";
import technology from "../../Images/tech.png";
import Technolgies from "./Technolgies";
import Navbar from "../Navbar/Navbar";
import { FiArrowRight } from "react-icons/fi";
import ReactReadMoreReadLess from "react-read-more-read-less";

const Team = () => {
  return (
    <>
      <Navbar />
      <div className="team">
        <div className="team__header">
          <h1>HIRE EXPERIENCED SOFTWARE DEVELOPMENT TEAM</h1>
        </div>
        <div className="team-container">
          <div className="img-container">
            <img src={team} alt="" />
          </div>
          <div className="content">
            <h1>The Cycle</h1>

            <img src={cycle} alt="" />
          </div>
        </div>

        <div className="hiringModel">
          <h1>Hiring Model</h1>
          <div className="hiring">
            <div className="card-container">
              <div>
                <div className="card">
                  <h3>- Full Time Hiring -</h3>
                  <ReactReadMoreReadLess
                    charLimit={100}
                    readMoreText={"Read More"}
                    readLessText={"Read Less"}
                    readMoreStyle={{ color: "blue" }}
                    readLessStyle={{ color: "blue" }}
                  >
                    This one is the most preferred option under our Hire a
                    Developer model. It engages direct and clear communication
                    with the person working on the project and to provide them
                    full control over the development process. You can hire
                    experts in web development, web designing and mobile app
                    development throughout the duration of your project
                    development.
                  </ReactReadMoreReadLess>
                  <span className="bar"></span>
                  <p>
                    - Duration: 8/hours per day, <br />5 days/week
                  </p>
                  <p>- Communication: Email, Skype, Phone</p>
                  <p>- Billing: Monthly</p>
                  <p>- Hiring Period: Minimum 1 Month</p>
                </div>
              </div>
              <div>
                <div className="card">
                  <h3>- Part Time Hiring -</h3>
                  <ReactReadMoreReadLess
                    charLimit={100}
                    readMoreText={"Read More"}
                    readLessText={"Read Less"}
                    readMoreStyle={{ color: "blue" }}
                    readLessStyle={{ color: "blue" }}
                  >
                    In part-time hiring model, we provide you with an expert who
                    will work for a minimum of 80 hours monthly towards your
                    project, employed for 5 days/week.
                  </ReactReadMoreReadLess>
                  <span className="bar"></span>

                  <p>
                    - Duration: 4/hours per day, <br />5 days/week
                  </p>

                  <p>- Communication: Email, Skype, Phone</p>
                  <p>- Billing: Monthly </p>
                  <p>- Hiring Period: Minimum 1 Month </p>
                </div>
              </div>
              <div>
                <div className="card Three">
                  <h3>- Hourly Hiring -</h3>
                  <ReactReadMoreReadLess
                    charLimit={100}
                    readMoreText={"Read More"}
                    readLessText={"Read Less"}
                    readMoreStyle={{ color: "blue" }}
                    readLessStyle={{ color: "blue" }}
                  >
                    This model perfectly fits for small projects. You can hire
                    our expert based on the number of working hours. The hiring
                    period is minimum 25 hours, which can be further extended as
                    per the business needs.
                  </ReactReadMoreReadLess>
                  <span className="bar"></span>

                  <p>- Communication: Email, Skype, Phone</p>
                  <p>- Billing: Hourly</p>
                  <p>- Hiring Period: Minimum 25 Hours</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="getStarted">
          <a href="/contact">Let's Start</a>
        </div>
        <h1>technologies we work with</h1>
        <div className="technologies">
          <div className="tech-content">
            <Technolgies />
          </div>
          <div className="tech-img">
            <img src={technology} alt="" />
          </div>
        </div>
        <div className="dedicated-hiring">
          <h1>HIRE DEDICATED DEVELOPERS</h1>
          <FiArrowRight
            className="fiArrowRight"
            size={80}
            style={{ marginLeft: "40px", paddingTop: "50px" }}
          />

          <a href="/contact">Contact Us</a>
        </div>
      </div>
    </>
  );
};

export default Team;
