import React from "react";
import CountUp from "react-countup";

const Counter = () => {
  return (
    <>
      <div className="counter-container">
        <div className="experience-container">
          <h1>
            <CountUp duration={5} end={3} />+
          </h1>
          <p>Years of experience</p>
        </div>
        <div className="client-container">
          <h1>
            <CountUp duration={5} end={85} />+
          </h1>
          <p>satisfied client</p>
        </div>
        <div className="project-container">
          <h1>
            <CountUp duration={5} end={50} />+
          </h1>
          <p>projects completed</p>
        </div>
        <div className="codes-container">
          <h1>
            <CountUp duration={5} end={30} />+
          </h1>
          <p>technical partners</p>
        </div>
      </div>
    </>
  );
};

export default Counter;
