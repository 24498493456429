import React from "react";
import "./TechPool.css";
import react from "../../Images/react.png";
import native from "../../Images/native.png";
import flutter from "../../Images/flutter.jpg";
import android from "../../Images/android.webp";
import angular from "../../Images/angular.png";
import net from "../../Images/Net.jpg";
import netCore from "../../Images/net_core.png";
// import mvc from "../../Images/mvc.jpg";
// import java from "../../Images/java.png";
import node from "../../Images/Node.png";
import ror from "../../Images/ror.png";
import shopify from "../../Images/shopify.png";
import devOps from "../../Images/devOps.png";
import wordPress from "../../Images/wordpress.png";

const TechPool = () => {
  return (
    <div className="techPool">
      <h1>Tech Pool</h1>
      <div className="tech-container">
        <div className="tech">
          <img src={react} alt="reactJs" />
        </div>

        <div className="tech">
          <img src={native} alt="reactJs" />
        </div>
        <div className="tech">
          <img src={flutter} alt="reactJs" />
        </div>
        <div className="tech">
          <img src={android} alt="reactJs" />
        </div>
        {/* <div className="tech">
          <img src={java} alt="reactJs" />
        </div> */}
        <div className="tech">
          <img src={net} alt="reactJs" />
        </div>
        <div className="tech">
          <img src={netCore} alt="reactJs" />
        </div>
        <div className="tech">
          <img src={angular} alt="reactJs" />
        </div>
        <div className="tech">
          <img src={node} alt="reactJs" />
        </div>
        <div className="tech">
          <img src={ror} alt="reactJs" />
        </div>
        <div className="tech">
          <img src={shopify} alt="reactJs" />
        </div>
        <div className="tech">
          <img src={wordPress} alt="reactJs" />
        </div>
        <div className="tech">
          <img src={devOps} alt="reactJs" />
        </div>
      </div>
    </div>
  );
};

export default TechPool;
