import React from "react";
import "./Home.css";
import HomeImg from "../../Images/Intro-bg.mp4";
import TypeWriter from "typewriter-effect";
// import Work from "./Work";
import TechPool from "./TechPool";
import Navbar from "../Navbar/Navbar";
import AboutImg from "../../Images/AboutImg.png";
import Sectors from "./Sectors";
import Webdevelopment from "../Services/Webdevelopment";
import MobileDevelopment from "../Services/MobileDevelopment";
import EcommDevelopment from "../Services/EcommDevelopment";
import StrategyConsulting from "../Services/StrategyConsulting";
// import { FiArrowRight } from "react-icons/fi";

const Home = () => {
  return (
    <>
      <Navbar />
      <div className="home">
        <section id="header">
          <div className="mask">
            <video loop autoPlay className="homeImage">
              <source src={HomeImg} type="video/mp4" />
            </video>
          </div>
          <div className="content123">
            {/* <h1>COMPANY</h1> */}
            <TypeWriter
              className="slogan"
              onInit={(typewriter) => {
                typewriter
                  .typeString("<p>You Think We Create.</p>")
                  .start()
                  .pauseFor(100)
                  .deleteAll()
                  .typeString("<p>Grow Your Business Digitally With Us</p>");
              }}
            />
          </div>
        </section>
        <section id="main-content">
          <div className="abt-content">
            <h1>About us</h1>
            <p>
              At Softcoding Solutions, we strive to excel in the invention and
              development of the industry’s most advanced and demanding
              technologies including mobile apps, web, eCommerce, mCommerce and
              alike.
            </p>

            <button>
              {" "}
              <a href="/about">Know More</a>{" "}
            </button>
          </div>
          <div className="abt-img">
            <img src={AboutImg} alt="" />
          </div>
        </section>

        <section id="Work">
          <h1 className="serviceHeader">services we provide</h1>
          <Webdevelopment />

          <MobileDevelopment />

          <StrategyConsulting />
          <EcommDevelopment />
          <div className="btn__work">
            <a className="servicesHomeBtn" href="/services">
              Checkout more services{" "}
              {/* <FiArrowRight className="arrowRigth" size={30} /> */}
            </a>
          </div>

          {/* <Work /> */}
        </section>
        <section id="sectorsWorkedWith">
          <Sectors />
        </section>
        <section id="techPool">
          <TechPool />
        </section>
      </div>
    </>
  );
};

export default Home;
