import React, { useState } from "react";
import "../Navbar.css";
import { FiChevronDown, FiMenu, FiX } from "react-icons/fi";
import Logo from "../../../Images/Logo.png";
import { Link } from "react-scroll";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [drop, setDrop] = useState(false);

  return (
    <div>
      <nav className="navbar">
        <a
          href="/"
          className="nav-logo"
          onClick={() => setOpen(false)}
          style={{ fontSize: "40px", fontWeight: "900" }}
        >
          <img src={Logo} alt="logo" />
        </a>
        <div onClick={() => setOpen(!open)} className="nav-icon">
          {open ? (
            <FiX color="#000" size={50} />
          ) : (
            <FiMenu color="#000" size={50} />
          )}
        </div>
        <ul className={open ? "nav-links active" : "nav-links"}>
          <li className="nav-items">
            <a href="/" className="nav-link" onClick={() => setOpen(false)}>
              Home
            </a>
          </li>

          <li className="nav-items">
            <a
              href="/about"
              className="nav-link"
              onClick={() => setOpen(false)}
            >
              About
            </a>
          </li>
          <li className="nav-items">
            <a
              href="/services"
              className="nav-link ServicesDropdown"
              onClick={() => setOpen(false)}
            >
              Services
            </a>
            <FiChevronDown
              onClick={() => setDrop(!drop)}
              className="DropArrow"
              size={30}
            />
          </li>
          <li className="nav-items">
            <a href="/team" className="nav-link" onClick={() => setOpen(false)}>
              Hire A Team
            </a>
          </li>
          <li className="nav-items">
            <a
              href="/contact"
              className="nav-link contact-nav"
              onClick={() => setOpen(false)}
            >
              Contact Us
            </a>
          </li>
          <li className="nav-items">
            <a
              href="/careers"
              className="nav-link"
              onClick={() => setOpen(false)}
            >
              Careers
            </a>
          </li>
        </ul>
        <div className={drop ? "DropDown activeDrop" : "DropDown"}>
          <ul>
            <li>
              <Link
                to="webDev"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => {
                  setDrop(false);
                  setOpen(false);
                }}
              >
                Web Development
              </Link>
            </li>
            <li>
              <Link
                to="mobileDev"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => {
                  setDrop(false);
                  setOpen(false);
                }}
              >
                Mobile Development
              </Link>
            </li>
            <li>
              <Link
                to="customDev"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => {
                  setDrop(false);
                  setOpen(false);
                }}
              >
                Custom Development
              </Link>
            </li>
            <li>
              <Link
                to="CrossDev"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => {
                  setDrop(false);
                  setOpen(false);
                }}
              >
                Cross Platform Development
              </Link>
            </li>
            <li>
              <Link
                to="ModernApp"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => {
                  setDrop(false);
                  setOpen(false);
                }}
              >
                Application Modernization
              </Link>
            </li>
            <li>
              <Link
                to="EcommDev"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => {
                  setDrop(false);
                  setOpen(false);
                }}
              >
                E-commerce Development
              </Link>
            </li>
            <li>
              <Link
                to="Strategy"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => {
                  setDrop(false);
                  setOpen(false);
                }}
              >
                IT Strategy & Consulting
              </Link>
            </li>
            <li>
              <Link
                to="digital"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => {
                  setDrop(false);
                  setOpen(false);
                }}
              >
                Digital Marketing
              </Link>
            </li>
            <li>
              <Link
                to="Graphic"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => {
                  setDrop(false);
                  setOpen(false);
                }}
              >
                Graphics/Logo Designing
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
