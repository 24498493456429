import React, { useEffect } from "react";
import "./About.css";
import AboutImage from "../../Images/AboutImage.png";
import Navbar from "../Navbar/Navbar";
import Counter from "./Counter";
import Technologies from "../Hire A Team/Technolgies";
import technology from "../../Images/technology.png";
import Aos from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <>
      <Navbar />
      <div className="about">
        <div className="about-content">
          <h1>About Us</h1>
          <p>
            At Softcoding Solutions, we strive to excel in the invention and
            development of the industry’s most advanced and demanding
            technologies including mobile apps, web, eCommerce, mCommerce and
            alike. We translate these technologies into value for our clients’
            customers giving them professional and best-in-class services;
            thereby helping businesses to grow to their full potential.
          </p>
        </div>
        <div className="about-img">
          <img src={AboutImage} alt="" />
        </div>
      </div>

      <div className="technologies__about">
        <h1>Technologies we work with</h1>
        <div className="technologies__work">
          <div className="content__img">
            <img src={technology} alt="" />
          </div>
          <div className="content__work">
            <Technologies />
          </div>
        </div>
      </div>

      <div className="counter-box" data-aos="fade-up">
        <Counter />
      </div>
    </>
  );
};

export default About;
