import React from "react";
import "./Services.css";
// import ServicesLayout from "../../Images/servicesLayout.png";
import Webdevelopment from "./Webdevelopment";
import MobileDevelopment from "./MobileDevelopment";
import CustomDevelopment from "./CustomDevelopment";
import CrossPlatformDevelopment from "./CrossPlatformDevelopment";
import AppModernization from "./AppModernization";
import EcommDevelopment from "./EcommDevelopment";
import StrategyConsulting from "./StrategyConsulting";
import DigitalMarketing from "./DigitalMarketing";
import GraphicLogo from "./GraphicLogo";
import ServiceNavbar from "../Navbar/ServicesNavbar/ServiceNavbar";
// import { useParams } from "react-router-dom";
// import { Link } from "react-router-dom";

const Serivces = () => {
  return (
    <>
      <ServiceNavbar />
      <div className="services">
        <div className="services-header">
          <h1>services</h1>
        </div>
      </div>

      <div className="services-container">
        <div id="webDev">
          <Webdevelopment />
        </div>
        <div id="mobileDev">
          <MobileDevelopment />
        </div>
        <div id="customDev">
          <CustomDevelopment />
        </div>
        <div id="CrossDev">
          <CrossPlatformDevelopment />
        </div>
        <div id="ModernApp">
          <AppModernization />
        </div>
        <div id="EcommDev">
          <EcommDevelopment />
        </div>
        <div id="Strategy">
          <StrategyConsulting />
        </div>
        <div id="digital">
          <DigitalMarketing />
        </div>
        <div id="Graphic">
          <GraphicLogo />
        </div>
      </div>
    </>
  );
};

export default Serivces;
