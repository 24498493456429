import React from "react";
import DigitalMarketingImg from "../../Images/DigitalMarketing.png";

const DigitalMarketing = () => {
  return (
    <div>
      <div className="DevelopmentL DigitalDevelopment">
        <div className="ImageL DigitalImage">
          <img src={DigitalMarketingImg} alt="Digital-development" />
        </div>
        <div className="ContentL DigitalContent">
          <h2>Digital marketing</h2>
          <p>
            We offer Digital Marketing services like SEO/SEM, boosting your
            social media presence, emails, marketing strategy and advertising
            campaigns.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DigitalMarketing;
