import React from "react";
import webDevelopment from "../../Images/webDevelopment.png";

const Webdevelopment = () => {
  return (
    <div>
      <div className="DevelopmentR webDevelopment">
        <div className="ContentR webContent">
          <h2>web development</h2>
          <p>
            We develop visually appealing websites that feature user-friendly
            and simple navigation. We create a design that suites your business
            and brand, while giving your customers a seamless user experience.
          </p>
        </div>
        <div className="ImageR webImage">
          <img src={webDevelopment} alt="web-development" />
        </div>
      </div>
    </div>
  );
};

export default Webdevelopment;
