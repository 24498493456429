import React from "react";
import GraphicDesigning from "../../Images/GraphicDesining.png";

const GraphicLogo = () => {
  return (
    <div>
      <div className="DevelopmentR GraphicD">
        <div className="ContentR GrapgicContent">
          <h2>graphics designing</h2>
          <p>
            We help you create visual elements for your clients such as logo,
            banners, advertisements for your product.
          </p>
        </div>
        <div className="ImageR GraphicImage">
          <img src={GraphicDesigning} alt="Graphic-development" />
        </div>
      </div>
    </div>
  );
};

export default GraphicLogo;
