import React from "react";
import crossplatformDevelopment from "../../Images/crossplatformDevelopment.png";

const CrossPlatformDevelopment = () => {
  return (
    <div className="DevelopmentL crossDevelopment">
      <div className="ImageL crossImage">
        <img src={crossplatformDevelopment} alt="cross-development" />
      </div>
      <div className="ContentL crossContent">
        <h2>Cross Platform App Development</h2>
        <p>
          Craft your application allowing users to access it across any
          platform.
        </p>
      </div>
    </div>
  );
};

export default CrossPlatformDevelopment;
