import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import "./Career.css";
import JoinUs from "../../Images/JoinUs.png";
import salary from "../../Images/salary.png";
import annualBudget from "../../Images/annualBudget.png";
import bonus from "../../Images/bonus.png";
import timeOff from "../../Images/timeOff.png";
import workMF from "../../Images/workMF.png";
import flexibleHours from "../../Images/flexibleHours.png";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
// import bullet from "../../Images/bullet.png";
import { SiCheckmarx } from "react-icons/si";
import { FaRegThumbsUp } from "react-icons/fa";
import ButtonBg from "../../Images/ButtonBg.gif";
import Aos from "aos";
import "aos/dist/aos.css";

const Careers = () => {
  const [expand1, setExpand1] = useState(false);
  const [expand2, setExpand2] = useState(false);
  const [expand3, setExpand3] = useState(false);
  const [expand4, setExpand4] = useState(false);
  const [expand5, setExpand5] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  return (
    <>
      <Navbar />
      <div className="career-container">
        <div className="JoinHead">
          <div className="JoinContent">
            <h1>Join our team</h1>
            <p>
              Softcoding Solutions has a workplace where you can grow, learn and
              get professional development opportunities. We have a highly
              collaborative environment and we are focused on delivering the
              best products possible for our clients. We like to keep our people
              happy and to maintain a relaxed work environment. We are in
              constant growth, always in search of more collaborators. <br />
              <br />
              Be part of our team!
            </p>
          </div>
          <div className="JoinImg">
            <img src={JoinUs} alt="" />
          </div>
        </div>

        {/* COMPANY BENEFITS */}

        <div className="JoinBenefits">
          <div className="benefitTitle">
            <h1>company benefits</h1>
            <p>
              See the great benefits that come with being a part of the team at
              Softcoding Solutions.
            </p>
          </div>
          <div className="benefitContent">
            <div className="benefitItem">
              <img src={salary} alt="" />
              <p>Competitive Salary</p>
            </div>
            <div className="benefitItem">
              <img src={annualBudget} alt="" />
              <p>Annual Attendance Conference Budget</p>
            </div>
            <div className="benefitItem">
              <img src={bonus} alt="" />
              <p>Productivity Bonus</p>
            </div>
            <div className="benefitItem">
              <img src={timeOff} alt="" />
              <p>Paid Time Off</p>
            </div>
            <div className="benefitItem">
              <img src={workMF} alt="" />
              <p>Work From Monday to Friday</p>
            </div>
            <div className="benefitItem">
              <img src={flexibleHours} alt="" />
              <p>Flexible Hours</p>
            </div>
          </div>
        </div>

        {/* WHY JOIN US ? */}

        <div className="joinUs">
          <div className="join__title">
            <h1>why join us ?</h1>
          </div>
          <div className="join__content">
            <div data-aos="fade-left" className="join__container1">
              <h2>Transparent & Open Communication</h2>
              <p>- Open discussions during retreats and meetings</p>
            </div>
            <div data-aos="fade-right" className="join__container2">
              <h2>Training & Development</h2>
              <p>- Programs designed to amplify your ability and skills</p>
            </div>
            <div data-aos="fade-left" className="join__container3">
              <h2>Rewards & Benefits</h2>
              <p>- To make you feel approved and recognized</p>
            </div>
            <div data-aos="fade-right" className="join__container4">
              <h2>Fun Connect</h2>
              <p>- Annual picnics,Friday fun and Connect activities</p>
            </div>
          </div>
        </div>

        {/* COMPANY OPPORTUNITIES */}

        <div className="companyOpportunities">
          <h1>COMPANY OPPORTUNITIES</h1>

          <div className="variousOpportunities">
            {/* OPPORTUNITY 1 */}

            <div className="opportunity">
              <div className="OpportunityHeader">
                {/* <img src={bullet} alt="" /> */}
                <h2>React JS developer</h2>
              </div>
              <div className="arrowToggle" onClick={() => setExpand2(!expand2)}>
                {expand2 ? (
                  <IoMdArrowDropup size={50} />
                ) : (
                  <IoMdArrowDropdown size={50} />
                )}
              </div>
            </div>
            <div
              className={
                expand2
                  ? "opportunityContent activeExpand"
                  : "opportunityContent"
              }
            >
              <p>
                We are looking for a talented professional to join our team as a
                React JS to work remotely. In a fast startup environment like
                this, you will collaborate with the customers to build awesome
                apps, by getting and transforming their requirements into apps
                where frontend and backend code is required.
              </p>
              <h2>
                <SiCheckmarx style={{ marginRight: "10px" }} /> Requirements and
                Duties:
              </h2>
              <li>
                Coordinating the workflow between the Graphic designer, the HTML
                coder and other team members.
              </li>
              <li>
                Coordinating with architects and business analysts to determine
                functionalities.
              </li>
              <li>
                {" "}
                Coordinating with the back-end developer in the process of
                building the RESTful API.
              </li>
              <li>
                {" "}
                Building and delivering a complete front-end application.
              </li>
              <li>
                {" "}
                Validating user actions on the client side and providing
                responsive feedback.
              </li>
              <li>
                {" "}
                Resolve application defects and issues in a timely manner.
              </li>
              <h2>
                <FaRegThumbsUp style={{ marginRight: "10px" }} /> Skill and
                Ability:
              </h2>
              <li>
                Highly-skilled at writing tested, idiomatic, and documented
                JavaScript, HTML and CSS.
              </li>
              <li>
                Ability to effectively communicate with external web services
                and third-party tools.
              </li>
              <li>
                Well acquainted with theoretical concepts of software
                engineering.
              </li>
              <li>
                Thorough understanding of CSS and JS methods for providing
                performance visual effects.
              </li>
              <li>
                Knowledge of documenting the code inline using JSDoc or other
                conventions.
              </li>
              <li>
                Decent understanding of the responsibilities of the API, caching
                layer, database, proxies, and other web services used in the
                system.
              </li>
              <a
                href="https://forms.gle/7Djf1bQPphtL4yp1A"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>Apply Now</button>
              </a>
            </div>

            {/* OPPORTUNITY 2 */}

            <div className="opportunity">
              <div className="OpportunityHeader">
                {/* <img src={bullet} alt="" /> */}
                <h2>node js developer</h2>
              </div>
              <div className="arrowToggle" onClick={() => setExpand3(!expand3)}>
                {expand3 ? (
                  <IoMdArrowDropup size={50} />
                ) : (
                  <IoMdArrowDropdown size={50} />
                )}
              </div>
            </div>
            <div
              className={
                expand3
                  ? "opportunityContent activeExpand"
                  : "opportunityContent"
              }
            >
              <p>
                We are looking for a talented professional to join our team as a
                Node JS Developer to work remotely. In a fast startup
                environment like this, you will collaborate with the customers
                to build awesome apps, by getting and transforming their
                requirements into apps where frontend and backend code is
                required.
              </p>

              <h2>
                <SiCheckmarx style={{ marginRight: "10px" }} /> Requirements and
                Duties:
              </h2>

              <li>
                Integration of user-facing elements developed by front-end
                developers with server side logic
              </li>
              <li> Writing efficient, reusable, testable, and tenable code</li>
              <li>
                {" "}
                Developing applications with low-latency and high-performance
                rates
              </li>

              <li> Third-party and data storage solutions integration</li>
              <li> Implementing automated testing platforms and unit tests</li>
              <li>
                Ensuring high security and data protection for the application
              </li>

              <h2>
                <FaRegThumbsUp style={{ marginRight: "10px" }} /> skill and
                ability:
              </h2>
              <li>
                Proven experience with Node.js and frameworks available for it
              </li>
              <li>
                Skilled with technologies like JavaScript, Json, Ajax, MySQL,
                MongoDb, NPM
              </li>
              <li> Experience with supporting technologies</li>
              <li> Good understanding of server-side templating languages</li>
              <li>
                Understanding of user authentication and authorization between
                multiple systems, servers, and environments
              </li>
              <li> Proficient with code versioning tools such as GitLab</li>
              <a
                href="https://forms.gle/7Djf1bQPphtL4yp1A"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>Apply Now</button>
              </a>
            </div>

            {/* OPPORTUNITY 3 */}

            <div className="opportunity">
              <div className="OpportunityHeader">
                {/* <img src={bullet} alt="" /> */}
                <h2>React Native Developer</h2>
              </div>
              <div className="arrowToggle" onClick={() => setExpand1(!expand1)}>
                {expand1 ? (
                  <IoMdArrowDropup size={50} />
                ) : (
                  <IoMdArrowDropdown size={50} />
                )}
              </div>
            </div>
            <div
              className={
                expand1
                  ? "opportunityContent activeExpand"
                  : "opportunityContent"
              }
            >
              <p>
                We are looking for a talented professional to join our team as a
                React Native Developer to work remotely. In a fast startup
                environment like this, you will collaborate with the customers
                to build awesome apps, by getting and transforming their
                requirements into apps where frontend and backend code is
                required.
              </p>

              <h2>
                <SiCheckmarx style={{ marginRight: "10px" }} />
                Requirements and Duties:
              </h2>

              <li>
                Build pixel-perfect, buttery smooth UIs across both mobile
                platforms.
              </li>
              <li>
                Leverage native APIs for deep integrations with both platforms.
              </li>
              <li>
                {" "}
                Diagnose and fix bugs and performance bottlenecks for
                performance that feels native.
              </li>

              <li>
                {" "}
                Reach out to the open-source community to encourage and help
                implement mission-critical software fixes—React Native moves
                fast and often breaks things.
              </li>
              <li>
                Maintain code and write automated tests to ensure the product is
                of the highest quality.
              </li>
              <li>Transition existing React web apps to React Native.</li>

              <h2>
                <FaRegThumbsUp style={{ marginRight: "10px" }} /> skill and
                ability:
              </h2>
              <li>
                {" "}
                Firm grasp of the JavaScript language and its nuances, including
                ES6+ syntax
              </li>
              <li>Knowledge of functional/ object-oriented programming</li>

              <li> Ability to write well-documented, clean Javascript code</li>
              <li>
                Rock-solid at working with third-party dependencies and
                debugging dependency conflicts{" "}
              </li>
              <li>
                Familiarity with native build tools, like XCode, Gradle Android
                Studio, IntelliJ
              </li>
              <li>
                Understanding of REST APIs, the document request model, and
                offline storage
              </li>
              <a
                href="https://forms.gle/7Djf1bQPphtL4yp1A"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>Apply Now</button>
              </a>
            </div>

            {/* OPPORTUNITY 4 */}

            <div className="opportunity">
              <div className="OpportunityHeader">
                {/* <img src={bullet} alt="" /> */}
                <h2>Angular js developer</h2>
              </div>
              <div className="arrowToggle" onClick={() => setExpand4(!expand4)}>
                {expand4 ? (
                  <IoMdArrowDropup size={50} />
                ) : (
                  <IoMdArrowDropdown size={50} />
                )}
              </div>
            </div>
            <div
              className={
                expand4
                  ? "opportunityContent activeExpand"
                  : "opportunityContent"
              }
            >
              <p>
                We are looking for a talented professional to join our team as a
                Angular JS developer to work remotely. In a fast startup
                environment like this, you will collaborate with the customers
                to build awesome apps, by getting and transforming their
                requirements into apps where frontend and backend code is
                required.
              </p>

              <h2>
                <SiCheckmarx style={{ marginRight: "10px" }} />
                Requirements and Duties:
              </h2>

              <li>Ensuring high performance on mobile and desktop.</li>
              <li>
                Coordinating the workflow between the Graphic designer, the HTML
                coder and other team members.
              </li>
              <li>
                Coordinating with architects and business analysts to determine
                functionalities.
              </li>

              <li>
                {" "}
                Coordinating with the back-end developer in the process of
                building the RESTful API.
              </li>
              <li>Building and delivering a complete front-end application.</li>
              <li>
                Validating user actions on the client side and providing
                responsive feedback.
              </li>
              <li>
                Resolve application defects and issues in a timely manner.
              </li>

              <h2>
                <FaRegThumbsUp style={{ marginRight: "10px" }} /> Skill and
                Ability:
              </h2>
              <li>
                {" "}
                Highly-skilled at writing tested, idiomatic, and documented
                JavaScript, HTML and CSS.
              </li>
              <li>
                Ability to effectively communicate with external web services
                and third-party tools.
              </li>
              <li>
                Thorough understanding of CSS and JS methods for providing
                performant visual effects.
              </li>
              <li>
                Knowledge of documenting the code inline using JSDoc or other
                conventions.
              </li>
              <li>Can provide SEO solutions for single page apps.</li>
              <li>
                Well acquainted with theoretical concepts of software
                engineering.
              </li>
              <li>
                Decent understanding of the responsibilities of the API, caching
                layer, database, proxies, and other web services used in the
                system.
              </li>
              <a
                href="https://forms.gle/7Djf1bQPphtL4yp1A"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>Apply Now</button>
              </a>
            </div>

            {/* OPPORTUNITY 5 */}

            <div className="opportunity">
              <div className="OpportunityHeader">
                {/* <img src={bullet} alt="" /> */}
                <h2>.net developer</h2>
              </div>
              <div className="arrowToggle" onClick={() => setExpand5(!expand5)}>
                {expand5 ? (
                  <IoMdArrowDropup size={50} />
                ) : (
                  <IoMdArrowDropdown size={50} />
                )}
              </div>
            </div>
            <div
              className={
                expand5
                  ? "opportunityContent activeExpand"
                  : "opportunityContent"
              }
            >
              <p>
                We are looking for a talented professional to join our team as a
                .NET Developer to work remotely. In a fast startup environment
                like this, you will collaborate with the customers to build
                awesome apps, by getting and transforming their requirements
                into apps where frontend and backend code is required.
              </p>

              <h2>
                <SiCheckmarx style={{ marginRight: "10px" }} /> Requirements and
                Duties :
              </h2>

              <li>
                Develop and write high quality .Net codes that meet customer
                requirements.
              </li>
              <li>
                Prepare technical documents as per established project
                requirements referring to industry standards.
              </li>
              <li>
                {" "}
                Ensure compliance with the documented software processes and
                procedures throughout the product life cycle.
              </li>

              <li>
                {" "}
                Implement best practices, standards and procedures including
                quality and delivery methodologies.
              </li>
              <li>
                Coordinate with architects and business analysts to determine
                functionalities.
              </li>
              <li>
                Resolve application defects and issues in a timely manner.
              </li>

              <h2>
                <FaRegThumbsUp style={{ marginRight: "10px" }} /> skill and
                ability :
              </h2>
              <li> Proven experience with DOT.Net development.</li>
              <li>
                Familiar with JavaScript or Angular JS or other scripting
                languages.
              </li>
              <li> Should have knowledge of WPF and MVVM techniques.</li>
              <li>
                Ability to integrate best qualitative practices in design and
                development aspects of programs.{" "}
              </li>
              <li>
                Ability to build flexible data models and seamless integration
                points.
              </li>
              <li>
                Can quickly respond to changes and modifications of the
                application.
              </li>
              <a
                href="https://forms.gle/7Djf1bQPphtL4yp1A"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>Apply Now</button>
              </a>
            </div>
          </div>
        </div>
        <div className="extraOpportunity">
          <div className="extraHeader">
            <h1>
              Don’t see the position you’re interest in? Send us your contact
              info!
            </h1>
          </div>
          <div className="extraContent">
            <img src={ButtonBg} alt="" />
            <a
              href="https://forms.gle/7Djf1bQPphtL4yp1A"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button>Apply Now</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Careers;
