import React from "react";
import modernizationDevelopment from "../../Images/modernizationDevelopment.png";

const AppModernization = () => {
  return (
    <div>
      <div className="DevelopmentR modernDevelopment">
        <div className="ContentR modernContent">
          <h2>
            application <br /> modernization
          </h2>
          <p>
            We provide software application upgradation services to give you
            better experience.
          </p>
        </div>
        <div className="ImageR modernImage">
          <img src={modernizationDevelopment} alt="modern-development" />
        </div>
      </div>
    </div>
  );
};

export default AppModernization;
