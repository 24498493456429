import React from "react";
import customDevelopment from "../../Images/customDevelopment.png";

const CustomDevelopment = () => {
  return (
    <div>
      <div className="DevelopmentR customDevelopment">
        <div className="ContentR customContent">
          <h2>custom software development services</h2>
          <p>
            Building custom software exclusive for your business that promotes
            your business growth.
          </p>
        </div>
        <div className="ImageR customImage">
          <img src={customDevelopment} alt="custom-development" />
        </div>
      </div>
    </div>
  );
};

export default CustomDevelopment;
