import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import Serivces from "./Components/Services/Serivces";
import Team from "./Components/Hire A Team/Team";
import ContactUs from "./Components/Contact Us/ContactUs";
import Footer from "./Components/Footer/Footer";
import Careers from "./Components/Careers/Careers";
import ScrollButton from "./Components/ScrollButton/ScrollButton";
import Internship from "./Components/Internship/Internship";
// import Webdevelopment from "./Components/Services/Webdevelopment";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/services" component={Serivces} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/contact" component={ContactUs} />
          <Route exact path="/careers" component={Careers} />
          <Route exact path="/internship" component={Internship} />
        </Switch>
      </Router>
      <ScrollButton />
      <Footer />
    </div>
  );
}

export default App;
