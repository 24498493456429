import React from "react";
import Navbar from "../Navbar/Navbar";
import "./Internship.css";
import ButtonBg2 from "../../Images/ButtonBg2.gif";

const Internship = () => {
  return (
    <div>
      <Navbar />
      <div className="internship__container">
        <div className="internship__header">
          <h1>internship at softcoding solutions</h1>
        </div>
        <div className="internship__content">
          <h3>apply now for internship at softcoding solutions</h3>
          <img src={ButtonBg2} alt="" />

          <a
            href="https://forms.gle/NVkrvCCvau3ngowr6"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button>apply now</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Internship;
