import React, { useEffect, useState } from "react";
import techData from "./TechData";

const Technolgies = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = techData.length;

  const autoScroll = true;
  let slideInterval;
  let intervalTime = 3000;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
    // console.log("next");
  };

  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [currentSlide]);

  return (
    <div className="slider">
      {techData.map((slide, index) => {
        return (
          <div
            className={index === currentSlide ? "slide current" : "slide"}
            key={index}
            // style={{ transform: `translate3d(${-index * 10}%, 0, 0)` }}
          >
            {index === currentSlide && (
              <div>
                <div className="web-tech">{slide.technology1}</div>
                <div className="web-techs">
                  <h2>{slide.title1}</h2>
                  <ul>
                    {slide.list1.map((val1, index) => {
                      return <li key={index}>{val1}</li>;
                    })}
                  </ul>
                  <h2>{slide.title2}</h2>
                  <ul>
                    {slide.list2.map((val2, index) => {
                      return <li key={index}>{val2}</li>;
                    })}
                  </ul>
                </div>
              </div>
            )}
          </div>
          // style={{ transform: `translate3d(${-index * 110}%, 0, 0)` }}
          // key={idx}
          // technology1={val.technology1}
          // title1={val.title1}
          // list1={val.list1.map((val1, index) => {
          //   return <li key={index}>{val1}</li>;
          // })}
          // title2={val.title2}
          // list2={val.list2.map((val2, index) => {
          //   return <li key={index}>{val2}</li>;
          // })}
        );
      })}
    </div>
  );
};

export default Technolgies;
